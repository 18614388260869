<template>
  <div class="summary-work">
    <router-link
      class="summary-work__link-image"
      :to="{
        name: 'work.details',
        params: {
          pArtist: value.artist.replace(/ /g, '-'),
          pTitle: value.title.replace(/ /g, '-'),
          id: value.id,
        },
      }"
    >
      <figure class="summary-work__figure">
        <img ref="workImg" id="workImg" :src="value.img" alt="" />
      </figure>
    </router-link>
    <div class="summary-work__header">
      <router-link
        class="flex-grow-1"
        :to="{
          name: 'work.details',
          params: {
            pArtist: value.artist.replace(/ /g, '-'),
            pTitle: value.title.replace(/ /g, '-'),
            id: value.id,
          },
        }"
      >
        <h2 class="summary-work__title">
          <div>{{ value.artist }}</div>
          <div>{{ value.title }}</div>
        </h2>
      </router-link>
      <div>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on">
              <v-icon color="base">mdi-dots-vertical</v-icon>
            </button>
          </template>

          <v-list>
            <v-list-item v-for="(item, i) in actionItems" :key="i">
              <v-list-item-title role="button" @click="item.event(value)">
                <v-icon color="base" size="20">{{ item.icon }}</v-icon>
                <span class="ml-2 caption">{{ item.title }}</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <!--fin summary-work__header-->
    <div class="summary-work__content">
      <div class="summary-work__data">
        <div>
          <div>{{ $t("total") }}</div>
          <div>
            <strong>{{ formatAmount(value.currency, value.paidValue) }}</strong>
          </div>
        </div>
        <div>
          <div>{{ $t("frames") }}</div>
          <div>
            <strong>{{ value.quantity }}</strong>
          </div>
        </div>
        <div>
          <div>{{ $t("totalWork") }}</div>
          <div>
            <strong>{{
              formatAmount(value.currency, value.totalWorkValue)
            }}</strong>
          </div>
        </div>
        <div>
          <div>{{ $t("ownership") }}</div>
          <div>
            <strong
              >{{
                Math.round(1000*((100 * value.quantity) / value.totalFrames)) / 1000
              }}%</strong
            >
          </div>
        </div>
        <div class="summary-work__footer">
          <div class="summary-work__graph">
            <div>{{ $t("evolutionPrice") }}</div>
            <div>
              <tippy arrow arrow-type="round" theme="dark">
                <template v-slot:trigger>
                  <GraphTransactions :values="value.trend" />
                </template>
                {{ $t("noSellNotice1") }} <br />{{ $t("noSellNotice2") }}
              </tippy>
            </div>
          </div>
          <div class="summary-work__actions">
            <v-btn
              color="primary"
              :to="{ name: 'work.buy', params: { id: value.id } }"
              >{{ $t("buy") }}
            </v-btn>

            <tippy arrow arrow-type="round" theme="dark">
              <template v-slot:trigger>
                <v-btn outlined color="primary">
                  {{ $t("sell") }}
                </v-btn>
              </template>
              {{ $t("noSellNotice1") }} <br />{{ $t("noSellNotice2") }}
            </tippy>
          </div>
        </div>
      </div>
    </div>
    <FramesWorkDialog
      v-if="framesModal"
      :id="value.id"
      :imageURL="value.img"
      @close="framesModal = false"
    />
    <Certificate
      v-if="showCertificate"
      :ownership="ownership"
      :nFrames="value.quantity"
      :id="value.id"
      :imageURL="value.img"
      :firstName="$store.state.userProfile.firstName"
      :lastName="$store.state.userProfile.lastName"
      :artist="value.artist.replace(/ /g, '-')"
      :title="value.title.replace(/ /g, '-')"
      @close="showCertificate = false"
    />
  </div>
</template>

<script>
import GraphTransactions from "@/components/Organisms/GraphTransactions";

import colorsAndIcons from "@/mixins/colorsAndIcons.js";
import helpers from "@/mixins/helpers.js";
// import Certificate from "@/components/Templates/Certificate";
const FramesWorkDialog = () => import(/* webpackChunkName: "FramesWorkDialog" */ "@/components/Organisms/FramesWorkDialog");
const Certificate = () => import(/* webpackChunkName: "Certificate" */ "@/components/Templates/Certificate");

export default {
  name: "SummaryWork",
  components: {
    GraphTransactions,
    FramesWorkDialog,
    Certificate,
  },
  mixins: [colorsAndIcons, helpers],
  props: {
    value: Object,
  },
  data: () => {
    return {
      framesModal: false,
      showCertificate: false,
    };
  },
  computed: {
    ownership() {
      return (
        Math.round(1000*((100 * this.value.quantity) / this.value.totalFrames)) / 1000
      );
    },
    actionItems() {
      return [
        {
          title: this.$t("certificate"),
          icon: "mdi-file-download",
          event: this.download,
        },
        { title: this.$t("seeFrames"), icon: "mdi-eye", event: this.seeFrames },
      ];
    },
  },
  methods: {
    seeFrames() {
      this.framesModal = true;
    },
    download() {
      this.showCertificate = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-work {
  --summary-work-image-size: 60px;
  @media only screen and (min-width: 1024px) {
    --summary-work-image-size: 180px;
  }
  display: grid;
  grid-template-columns: var(--summary-work-image-size) 1fr;
  column-gap: 8px;
  margin-bottom: 12px;
  padding: 8px;
  border-radius: 4px;
  background: var(--color-grey-light);
  @media only screen and (min-width: 1024px) {
    padding: 12px;
    grid-template-columns: var(--summary-work-image-size) 1fr;
    grid-template-rows: auto auto;
  }
  &__link-image {
    @media only screen and (min-width: 1024px) {
      grid-row: 1 / -1;
    }
  }
  &__figure {
    width: var(--summary-work-image-size);
    height: var(--summary-work-image-size);
    margin-right: 12px;
    padding: 4px;
    border: 1px solid var(--color-grey);
    flex-shrink: 0;
    display: flex;
    background-color: var(--color-white);
    @media only screen and (min-width: 1024px) {
      width: var(--summary-work-image-size);
      height: var(--summary-work-image-size);
    }
    img {
      margin: auto;
      width: auto;
      max-height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
  &__header {
    grid-column-start: 2;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    font-weight: 600;
    width: calc(100% - 40px);
    font-size: var(--font-base-size);
    line-height: 1.2;
    > :first-child {
      color: var(--color-primary);
    }
    > :last-child {
      font-weight: 300;
    }
  }
  &__content {
    grid-column: 1 / -1;
    @media only screen and (min-width: 1024px) {
      grid-column: auto;
      grid-column-start: 2;
    }
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__data {
    border-top: 1px solid var(--color-grey);
    padding-top: 4px;
    margin-top: 4px;
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-extra-small);
    font-weight: 200;
    > div {
      margin-right: 12px;
    }
    strong {
      font-weight: 600;
    }
  }
  &__footer {
    border-top: 1px solid var(--color-grey);
    padding-top: 4px;
    margin-top: 4px;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__graph {
    & > :last-child {
      max-width: 200px;
    }
  }
  &__actions {
    display: flex;
    margin-top: auto;
    justify-content: flex-end;
    > * {
      margin-left: 8px;
    }
  }
}
</style>
