<template>
  <div class="graph-transaction">
    <v-sparkline
      :value="values"
      label-size="8"
      :labels="labels"
      padding="16"
      color="base"
      line-width="4"
      :radius="6"
      stroke-linecap="round"
      smooth
    ></v-sparkline>
  </div>
</template>

<script>
export default {
  props: {
    values: Array,
    labels: Array,
  },
};
</script>

<style lang="scss" scoped>
.graph-transaction {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  svg {
    margin: auto;
  }
}
</style>
